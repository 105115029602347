import { Component, HostListener, OnInit } from "@angular/core";
import {
    Router,
    NavigationCancel,
    NavigationEnd,
    NavigationStart,
} from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { filter } from "rxjs/operators";
declare let $: any;
import { AuthServiceService } from "../app/core/services/auth/auth-service.service";
import { SigninInterface } from "./core/interface/auth-interface/signin-interface";
import { StudentServiceService } from "./core/services/student/student-service.service";
import { SharedDataService } from "./core/common/sharedDataService";
import { EvenPopupComponent } from "./modules/even-popup/even-popup.component";
import { MatDialog } from "@angular/material/dialog";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent {
    // @HostListener('window:beforeunload', ['$event'])
    // beforeunloadHandler(event: any) {
    //     localStorage.removeItem("visitors");
    //     return false;
    //     //I have used return false but you can your other functions or any query or condition
    // }
    getUrlSubjectId: any;
    location: any;
    routerSubscription: any;
    dataLocal: SigninInterface;
    urlSubject: any;
    studentType: any;

    constructor(
        public dialog: MatDialog,
        private router: Router,
        private authService: AuthServiceService,
        private studentServiceService: StudentServiceService,
        private sharedDataService: SharedDataService
    ) {
        var countdownLocal = localStorage.getItem("countdown-level-test");
        var timeCountLocal = JSON.parse(countdownLocal);
        this.countVisitors();
        this.router.events.subscribe(async (event: any) => {
            if (event instanceof NavigationStart) {
                if (countdownLocal) {
                    if (event["url"] != `/profile/level-exam-all/level-test/${timeCountLocal.mainId}`) {
                        this.router.navigate([`/profile/level-exam-all/level-test/${timeCountLocal.mainId}`], {
                            replaceUrl: true,
                        }).then(() => { window.location.reload(); });
                    }
                }
            }

        });

    }

    ngOnInit() {
        // this.getVisitor();
        this.recallJsFuntions();
        this.checkLogin_();
        this.loadCourseData();
        this.loadAboutMeData();
        this.loadNewsData();
        this.loadContactData();
        this.loadDocumentData();
        var dataToday = new Date();
        this.router.events.forEach((event) => {
            if (event instanceof NavigationStart) {
                if (event["url"] == "/main") {
                    if (
                        localStorage.getItem("showbanner") != null &&
                        localStorage.getItem("showbanner") != undefined
                    ) {
                        var date = new Date(localStorage.getItem("showbanner"));
                        if (dataToday.getDate() > date.getDate()) {
                            this.openDialog();
                        }
                    } else {
                        this.openDialog();
                    }
                }

                var url = event["url"];
                this.urlSubject = url.split("/");

                if (
                    event["url"] ==
                    "/course/course-detail/" + this.urlSubject[3]
                ) {
                    setTimeout(() => {
                        this.sharedDataService.getDataCourse.subscribe(
                            async (message) => {
                                var subject = message.dataSubject;
                                if (subject != null && subject != undefined) {
                                    var result = subject.find(
                                        (x) => x.subjectId == this.urlSubject[3]
                                    );
                                    if (this.studentType == "student") {
                                    } else {
                                        var dataUser = result.userType.split(',');

                                        var serviceContractorFind = dataUser.find(s => s == 'serviceContractor');
                                        var governmentEmployeFind = dataUser.find(g => g == 'governmentEmploye');
                                        var governmentOfficerFind = dataUser.find(o => o == 'governmentOfficer');

                                        if (dataUser == 'general' || serviceContractorFind != null || governmentEmployeFind != null || governmentOfficerFind != null) {
                                        } else {
                                            this.router.navigate([`/course`], {
                                                replaceUrl: true,
                                            });
                                        }

                                        // if (
                                        //     result.userType ==
                                        //     this.studentType ||
                                        //     this.studentType == null
                                        // ) {
                                        // } else {
                                        //     this.router.navigate([`/course`], {
                                        //         replaceUrl: true,
                                        //     });
                                        // }
                                    }
                                }
                            }
                        );
                    }, 500);
                }
                if (
                    event["url"] ==
                    "/course/exam-before-class/" + this.urlSubject[3]
                ) {
                    setTimeout(() => {
                        this.sharedDataService.getDataCourse.subscribe(
                            async (message) => {
                                var subject = message.dataSubject;
                                if (subject != null && subject != undefined) {
                                    var result = subject.find(
                                        (x) => x.subjectId == this.urlSubject[3]
                                    );
                                    if (this.studentType == "student") {
                                    } else {
                                        var dataUser = result.userType.split(',');

                                        var serviceContractorFind = dataUser.find(s => s == 'serviceContractor');
                                        var governmentEmployeFind = dataUser.find(g => g == 'governmentEmploye');
                                        var governmentOfficerFind = dataUser.find(o => o == 'governmentOfficer');

                                        if (dataUser == 'general' || serviceContractorFind != null || governmentEmployeFind != null || governmentOfficerFind != null) {
                                        } else {
                                            this.router.navigate([`/course`], {
                                                replaceUrl: true,
                                            });
                                        }

                                        // if (
                                        //     result.userType == this.studentType
                                        // ) {
                                        // } else {
                                        //     this.router.navigate([`/course`], {
                                        //         replaceUrl: true,
                                        //     });
                                        // }
                                    }
                                }
                            }
                        );
                    }, 500);
                }
                if (
                    event["url"] ==
                    "/course/score-exam/" + this.urlSubject[3]
                ) {
                    setTimeout(() => {
                        this.sharedDataService.getDataCourse.subscribe(
                            async (message) => {
                                var subject = message.dataSubject;
                                if (subject != null && subject != undefined) {
                                    var result = subject.find(
                                        (x) => x.subjectId == this.urlSubject[3]
                                    );
                                    if (this.studentType == "student") {
                                    } else {
                                        var dataUser = result.userType.split(',');

                                        var serviceContractorFind = dataUser.find(s => s == 'serviceContractor');
                                        var governmentEmployeFind = dataUser.find(g => g == 'governmentEmploye');
                                        var governmentOfficerFind = dataUser.find(o => o == 'governmentOfficer');

                                        if (dataUser == 'general' || serviceContractorFind != null || governmentEmployeFind != null || governmentOfficerFind != null) {
                                        } else {
                                            this.router.navigate([`/course`], {
                                                replaceUrl: true,
                                            });
                                        }

                                        // if (
                                        //     result.userType == this.studentType
                                        // ) {
                                        // } else {
                                        //     this.router.navigate([`/course`], {
                                        //         replaceUrl: true,
                                        //     });
                                        // }
                                    }
                                }
                            }
                        );
                    }, 500);
                }
                if (event["url"] == "/course/learn/" + this.urlSubject[3]) {
                    setTimeout(() => {
                        this.sharedDataService.getDataCourse.subscribe(
                            async (message) => {
                                var subject = message.dataSubject;
                                if (subject != null && subject != undefined) {
                                    var result = subject.find(
                                        (x) => x.subjectId == this.urlSubject[3]
                                    );
                                    if (this.studentType == "student") {
                                    } else {
                                        var dataUser = result.userType.split(',');

                                        var serviceContractorFind = dataUser.find(s => s == 'serviceContractor');
                                        var governmentEmployeFind = dataUser.find(g => g == 'governmentEmploye');
                                        var governmentOfficerFind = dataUser.find(o => o == 'governmentOfficer');

                                        if (dataUser == 'general' || serviceContractorFind != null || governmentEmployeFind != null || governmentOfficerFind != null) {
                                        } else {
                                            this.router.navigate([`/course`], {
                                                replaceUrl: true,
                                            });
                                        }
                                        // if (
                                        //     !result.userType.split(",").includes(this.studentType)
                                        // ) {
                                        //     // if(){

                                        //     // }
                                        //     this.router.navigate([`/course`], {
                                        //         replaceUrl: true,
                                        //     });
                                        // }
                                    }
                                }
                            }
                        );
                    }, 500);
                }
                if (
                    event["url"] ==
                    "/course/exam-after-class/" + this.urlSubject[3]
                ) {
                    setTimeout(() => {
                        this.sharedDataService.getDataCourse.subscribe(
                            async (message) => {
                                var subject = message.dataSubject;
                                if (subject != null && subject != undefined) {
                                    var result = subject.find(
                                        (x) => x.subjectId == this.urlSubject[3]
                                    );
                                    if (this.studentType == "student") {
                                    } else {
                                        var dataUser = result.userType.split(',');

                                        var serviceContractorFind = dataUser.find(s => s == 'serviceContractor');
                                        var governmentEmployeFind = dataUser.find(g => g == 'governmentEmploye');
                                        var governmentOfficerFind = dataUser.find(o => o == 'governmentOfficer');

                                        if (dataUser == 'general' || serviceContractorFind != null || governmentEmployeFind != null || governmentOfficerFind != null) {
                                        } else {
                                            this.router.navigate([`/course`], {
                                                replaceUrl: true,
                                            });
                                        }

                                        // if (
                                        //     result.userType == this.studentType
                                        // ) {
                                        // } else {
                                        //     this.router.navigate([`/course`], {
                                        //         replaceUrl: true,
                                        //     });
                                        // }
                                    }
                                }
                            }
                        );
                    }, 500);
                }

                if (event["url"] != "/course/learn/" + this.urlSubject[3]) {
                    const interval_id = window.setInterval(function () { },
                        Number.MAX_SAFE_INTEGER);
                    for (let i = 1; i < interval_id; i++) {
                        window.clearInterval(i);
                    }
                }
            }
        });
    }

    countVisitors() {
        if (performance.navigation.type === PerformanceNavigation.TYPE_RELOAD) {
        } else {
            this.authService.GenVisitor().then(res => { });
            // ยิง api
        }
    }



    trackEvent() {
        Swal.fire({
            icon: "warning",
            title: "กรุณาทำข้อสอบวัดระดับให้เสร็จ!",
            html: `<p style='display: inline-table;'>หากออกจากหน้าทำข้อสอบวัดระดับ เวลาจะไม่หยุดจนกว่าจะหมดเวลา!</p>`,
            confirmButtonColor: "var(--mainColor)",
        }).then((result) => {
            if (result.isConfirmed) {
                this.router.navigate([`/profile/exams-level-test`], {
                    replaceUrl: true,
                });
            }
        });
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel
                )
            )
            .subscribe((event) => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    checkLogin_() {
        var dataResponse: any;
        this.authService.checkLogin().then(
            (response) => {
                dataResponse = response;

                if (dataResponse.message == "Login-success") {
                    var local = localStorage.getItem("currentUserLogin");
                    if (local != null) {
                        this.dataLocal = JSON.parse(local);
                        this.studentServiceService
                            .findDataStudent(this.dataLocal.id.toString())
                            .then((response) => {
                                if (response.status == true) {
                                    this.sharedDataService.changeDataUserLogin(
                                        response
                                    );
                                    if (response.result.studentType == true) {
                                        this.studentType = "student";
                                    } else {
                                        this.studentType = "general";
                                    }
                                } else {
                                    this.studentServiceService.FindDataUser(this.dataLocal.id.toString()).then((res) => {

                                        if (res != '') {
                                            this.sharedDataService.changeDataUserLogin(
                                                res
                                            );
                                            this.studentType = "teacher";
                                        }

                                    })
                                }



                            });
                    } else {
                    }
                }
            },
            (err) => {
                if (err.status === 401) {
                    localStorage.removeItem("currentUserLogin");
                    // this.router.navigate([`/auth/errorAnth`], { replaceUrl: true });
                    this.sharedDataService.changeDataUserLogin(
                        "please-sign-in"
                    );
                }

                // เชื่อมต่อ api ไม่ได้
                if (
                    err.statusText === "Unknown Error" ||
                    err.statusText === "Not Found"
                ) {
                    // this.router.navigate([`/auth/errorConnectApi`], { replaceUrl: true });
                }
            }
        );
    }

    loadCourseData() {
        this.studentServiceService.findDataAllCourse().then(
            (res) => {
                this.sharedDataService.changeDataCourse(res);
            },
            (error) => {
                console.log("error : ", error);
            }
        );
    }

    loadAboutMeData() {
        this.studentServiceService.GetFetchDataAboutMe().then(
            (res) => {
                this.sharedDataService.changeDataAboutMe(res);
            },
            (error) => {
                console.log("error : ", error);
            }
        );
    }

    loadNewsData() {
        this.studentServiceService.GetFetchDataNews().then(
            (res) => {
                this.sharedDataService.changeDataNews(res);
            },
            (error) => {
                console.log("error : ", error);
            }
        );
    }

    loadContactData() {
        this.studentServiceService.GetFetchDataContact().then(
            (res) => {
                this.sharedDataService.changeDataContact(res);
            },
            (error) => {
                console.log("error : ", error);
            }
        );
    }

    loadDocumentData() {
        this.studentServiceService.GetFecthDataSiteRef().then(
            (res) => {
                this.sharedDataService.changeDataDocument(res);
            },
            (error) => {
                console.log("error : ", error);
            }
        );
    }

    openDialog(): void {
        var dialogRef: any;
        this.studentServiceService.getPlaCardList().then(
            (response) => {
                if (response.status) {
                    var dataEvenPopup = response.data.filter(
                        (x) => x.typePlubic == "even popup"
                    );
                    if (dataEvenPopup.length > 0) {
                        dialogRef = this.dialog.open(EvenPopupComponent, {
                            data: response.data,
                            disableClose: false,
                            panelClass: "myapp-no-padding-dialog",
                            minWidth: "90%",
                            // minHeight: '90%'
                        });
                    }
                }
            },
            (error) => {
                console.log("error : ", error);
            }
        );

        if (dialogRef != undefined)
            dialogRef.afterClosed().subscribe((result) => {
                console.log("The dialog was closed");
            });
    }

}
