<header class="header-area">
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <!-- <div class="col-lg-1"></div> -->
                <div class="col-lg-9" *ngIf="contactData != null">
                    <!-- <ul class="top-header-contact-info"> -->
                    <!-- <a class="navbar-brand logo-big" routerLink="/main"><img src="{{nameTiTle4}}"
                                alt="{{nameTiTle4}}"></a> -->
                    <div class="title-header title-header-media">
                        <!-- <pre> -->
                        <h2>{{nameTiTle4}}</h2>
                        <h4>Mahachulalongkornrajavidyalaya University NakhonSawan Campus</h4>
                        <!-- <h4>nbc.mcu.ac.th</h4> -->
                        <!-- </pre> -->

                    </div>
                    <!-- </ul> -->
                </div>
                <div class="col-lg-3">
                    <!-- <div class="top-header-btn"> -->
                    <ul class="top-header-contact-info" style="float: right;">
                        <li style="margin-bottom: 5px;">
                            <i class='bx bxs-phone-call'></i>
                            <span style="font-size: 12px;">{{langAll.contacta
                                | translate}}</span>
                            <span style="font-size: 12px;">{{telData}}
                                <div *ngIf="telData == null || telData == '' || telData == null">
                                    <span>* {{langAll.nodata | translate}}</span>
                                </div>
                            </span>
                        </li>
                        <li>
                            <i class='bx bxs-envelope'></i>
                            <span style="font-size: 12px;">{{langAll.email |
                                translate}}</span>
                            <span style="font-size: 12px;">{{emailData}}
                                <div *ngIf="emailData == null || emailData == '' || emailData == null">
                                    <span>*{{langAll.nodata | translate}}</span>

                                </div>
                            </span>
                        </li>
                        <!-- <li>
                            <i class='bx bxs-bug'></i>
                            <span style="font-size: 15.5px;">{{langAll.problemreport
                                | translate}}</span>
                            <span style="font-size: 14px;">
                                <a href="/report-problem">{{langAll.reportyourproblem
                                    | translate}}</a>
                            </span>
                        </li> -->
                    </ul>
                    <!-- </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="underline-top-header"></div>
    <!-- <a routerLink="/main"> -->
    <!-- </a> -->
    <div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container container-fluid">
            <!-- <div class="logo-main">
                <a routerLink="/main">
                    <img class="image-main" src="{{logoTiTle2}}" alt="{{logoTiTle2}}"></a>
            </div> -->
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand button-dis" routerLink="/main">
                    <img class="image-main img-sticky" src="{{logoTiTle2}}" alt="{{logoTiTle2}}"></a>
                <div *ngIf="showMenuSignIn">
                    <a routerLink="authentication/signin" class="default-btn signin-btn signin-btn-sticky"><i
                            class='bx bx-log-in icon-arrow before' style="color: var(--color-4-1);"></i>
                        <span class="label" style="white-space: nowrap;color: var(--mat-paginator);">{{langAll.signin
                            | translate}}</span><i class="bx bx-log-in icon-arrow after"
                            style="color: var(--color-4);"></i></a>
                </div>
                <button class="navbar-toggler" type="button" (click)="toggleClass()" style="margin: 15px;">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="spinner-grow text-danger" role="status" *ngIf="loading">
                    <span class="visually-hidden">{{langAll.loading |
                        translate}}...</span>
                </div>
                <div class="collapse navbar-collapse nav-profile" id="navbarSupportedContent" *ngIf="!showMenuSignIn">
                    <ul class="navbar-nav">
                        <li class="nav-item-name" style="align-content: center;">
                            <a href="javascript:void(0)" class="nav-link">
                                <div class="post-author">
                                    <div *ngIf="noImg">
                                        <img style="object-fit: cover;" *ngIf="profileImage" src="{{profileImage}}"
                                            class="d-inline-block rounded-circle mr-2"
                                            onerror="this.src='../../../../assets/img/no-img.png'" />
                                        <span class="name-title" *ngIf="studentName">{{studentName}}</span>
                                        <span class="badge rounded-pill"
                                            style="font-size: 10px; margin-left: 5px; margin-right: 5px;"
                                            *ngIf="getType.result && getType.result.studentType == true">
                                            <i class='bx bxs-graduation'
                                                style="margin-top: -3px;display: contents;"></i>
                                        </span>
                                        <span class="badge rounded-pill"
                                            style="font-size: 10px; margin-left: 5px; margin-right: 5px;"
                                            *ngIf="getType && getType.roleId == 4">
                                            <i class='bx bxs-chalkboard'
                                                style="margin-top: -3px;display: contents;"></i>
                                        </span>
                                        <i id="chevron" class='bx bx-chevron-down' style="margin-left: 5px;"></i>
                                    </div>

                                </div>
                            </a>

                            <ul class="dropdown-menu dropdown-menu-profile">
                                <li class="nav-item"><a routerLink="/profile" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:
                                        true}">{{langAll.profile | translate}}</a>
                                </li>

                                <!-- <li class="nav-item"><a routerLink="/profile/pending-courses-user" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                    true}">{{langAll.yourorder | translate}}</a></li> -->
                                <li class="nav-item"><a routerLink="/profile/list-courses-user" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">{{langAll.allyourcourses |
                                        translate}}</a></li>
                                <!-- <li class="nav-item"><a routerLink="/profile/level-test" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">{{langAll.leveltest |
                                        translate}}</a></li> -->
                                <li class="nav-item"><a routerLink="/profile/list-certificate" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">{{langAll.yourcertificate |
                                        translate}}</a></li>
                                <li class="nav-item" *ngIf="checkLocalTeacher!=null&&checkLocalTeacher.role=='Teacher'">
                                    <a (click)="navigateToExternal()" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:
                                        true}" style="cursor: pointer;">{{langAll.accesstoteacher|translate}}</a>
                                </li>
                                <li class="nav-item"><a (click)="onClicklogOut()" class="nav-link"
                                        routerLinkActive="active" style="cursor: pointer;" [routerLinkActiveOptions]="{exact:
                                        true}">{{langAll.signout | translate}}</a></li>
                            </ul>
                        </li>
                    </ul>

                </div>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a href="javascript:void(0)" routerLink="/main" class="nav-link"
                                id="nav-active" style="padding-left: 20px;padding-right: 20px;"
                                routerLinkActive="active">{{'MENU.HOME' |
                                translate}}</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" routerLink="/about" class="nav-link"
                                id="nav-active" routerLinkActive="active">{{'MENU.ABOUT'
                                | translate}}</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" routerLink="/news" class="nav-link"
                                id="nav-active" routerLinkActive="active">{{'MENU.MESSAGE'
                                | translate}}</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" routerLink="/course" class="nav-link"
                                id="nav-active" routerLinkActive="active">{{'MENU.COURSE'
                                | translate}}</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" id="nav-active"
                                routerLink="/profile/level-exam-all" class="nav-link"
                                routerLinkActive="active">{{langAll.thetest |translate}}</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" id="nav-active" routerLink="/questions"
                                class="nav-link" routerLinkActive="active">{{'MENU.Q&A'
                                | translate}}</a>
                        </li>
                        <li class="nav-item"><a href="javascript:void(0)" routerLink="/mamual" id="nav-active"
                                class="nav-link" routerLinkActive="active">{{'MENU.USE'
                                | translate}}</a>
                        </li>
                        <li class=" menu-disable nav-item"><a href="javascript:void(0)" routerLink="/contact"
                                id="nav-active" class="nav-link" routerLinkActive="active">{{'MENU.CONTACTUS'
                                | translate}}</a>
                        </li>
                        <!-- </div> -->
                    </ul>
                </div>
                <div class="others-option menu-disable" style="display: flex;">
                    <div class="others-option navbar-collapse" style="margin-right: 20px;">
                        <ul class="navbar-nav" style="margin-right: 0px;">
                            <li class="nav-item-name">
                                <a href="javascript:void(0)" class="nav-link">
                                    <div class="dropdown language-switcher d-inline-block dropdown"
                                        [class.active]="classApplied2">
                                        <button class="dropdown-toggle" type="button" (click)="toggleClass2()"
                                            style="border: none;display: contents;">
                                            <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" height="20">
                                            <img *ngIf="flagvalue === undefined" src="{{defaultFlag}}" height="20">
                                            <span><i class='bx bx-chevron-down'></i></span>
                                            <!-- <span *ngIf="langStoreValue == 'en'">English <i
                                                    class='bx bx-chevron-down'></i></span> -->
                                        </button>
                                    </div>
                                </a>
                                <ul class="dropdown-menu" style="margin-left: -87px;">
                                    <li class="nav-item">
                                        <a href="javascript:void(0);" class="dropdown-item"
                                            *ngFor="let item of listLang"
                                            (click)="setLanguage(item.text, item.lang, item.flag)">
                                            <img src="{{item.flag}}" class="flag-img"
                                                style="width: 10%;margin-right: 10px;">
                                            <span class="align-middle">{{item.text}}</span>
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="search-box d-inline-block" style="margin: auto;">
                        <i class='bx bx-search' (click)="toggleClass3()"></i>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <!-- <div class="search-overlay-layer"></div> -->
            <div class="search-overlay-layer">
                <div class="search-overlay-close" (click)="toggleClass3()">
                    <span class="search-overlay-close-line"></span>
                    <span class="search-overlay-close-line"></span>
                </div>
            </div>
            <!-- <div class="search-overlay-layer"></div> -->
            <!-- <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div> -->
            <div class="search-overlay-form">
                <!-- <form>
                    <input type="text" class="input-search"
                        placeholder="ค้นหา...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form> -->
                <div class="form">
                    <input type="text" class="input-search" placeholder="{{langAll.search | translate}}"
                        [(ngModel)]="search" (keyup.enter)="somethingChanged()">
                    <button type="submit" (click)="somethingChanged()"><i class='bx bx-search-alt'></i></button>
                </div>
            </div>
        </div>
    </div>
</div>