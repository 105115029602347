<div class="home-slides" *ngIf="imgBanner.length > 0">
  <div style="margin-bottom: -48px;z-index: 2;position: relative;padding: 12px 12px;float: right;">
    <span class="icon-close" (click)="onClickNotshow('off')">
      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
        <path fill="currentColor"
          d="M18.3 5.71a.996.996 0 0 0-1.41 0L12 10.59L7.11 5.7A.996.996 0 1 0 5.7 7.11L10.59 12L5.7 16.89a.996.996 0 1 0 1.41 1.41L12 13.41l4.89 4.89a.996.996 0 1 0 1.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4" />
      </svg>
    </span>
  </div>
  <owl-carousel-o [options]="homeSlides">
    <ng-template carouselSlide *ngFor="let item of imgBanner">
      <div class="main-banner" style=" height: 80vh;">
        <div>
          <!-- <img src="{{item}}" alt="image" style=" height: 80vh;"> -->
          <img src="../../../assets/img/img-mahachula/mahachula-event-1.jpg" alt="image" style=" height: 80vh;">
        </div>
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <!-- <div class="main-banner-content">
                <span class="sub-title">ความรู้คืออาวุธที่ดีที่สุด</span>
                <h1>Learning Management System</h1>
                <p>การศึกษาเป็นทางเลือกที่เหมาะสมที่สุดสำหรับองค์กร ธุรกิจของคุณ
                  และระบบการศึกษาออนไลน์ของคุณ สร้าง
                  หลักสูตรออนไลน์ของคุณตอนนี้มีเทมเพลตหน้า ตัวเลือกสี
                  และคุณสมบัติเมนูไม่จำกัด</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>
<!-- <div class="row"> -->
<div style="text-align: center;
    margin-top: 10px;margin-bottom: 10px;">
  <a (click)="onClickNotshow('closed')" class="default-btn" style="cursor: pointer;">
    <i class='bx bx-show-alt icon-arrow before'></i>
    <span class="label">{{langAll.notshowmsg | translate}}</span>
    <i class='bx bxs-hide icon-arrow after'></i>

    <!-- <i class="bx bx-show-alt "></i> -->
  </a>
</div>
<!-- </div> -->