import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SharedDataService } from 'src/app/core/common/sharedDataService';
import { SigninInterface } from 'src/app/core/interface/auth-interface/signin-interface';
import { CartService } from 'src/app/core/services/cartService/cart.service';
import { LanguageService } from 'src/app/core/services/languageService/language.service';
import { Constants } from '../../core/constants/constant';
import { TranslateService } from '@ngx-translate/core';
import { LanguageAll } from '../../modules/language-interface/language-item-all'
import { Idle, DEFAULT_INTERRUPTSOURCES } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import Swal from 'sweetalert2';
import { AuthServiceService } from 'src/app/core/services/auth/auth-service.service';
@Component({
    selector: 'app-header-style-one',
    templateUrl: './header-style-one.component.html',
    styleUrls: ['./header-style-one.component.scss']
})
export class HeaderStyleOneComponent implements OnInit {
    logoTiTle1: String = Constants.LOGO_IMG_1.toString();
    logoTiTle2: String = Constants.LOGO_IMG_2.toString();
    nameTiTle4: String = Constants.TITLE_LOGO_1.toString();
    dataLocal: SigninInterface;
    showMenuSignIn: boolean = false;
    checkLogin: boolean = true;
    cartList: boolean = true;
    loading: boolean = false;
    studentName: String = "";
    profileImage: String = "";
    noImg: boolean = true;
    countCartList: number = 0;
    telData: any;
    emailData: any;
    contactData: any = [];
    search: string = '';

    langStoreValue: string;
    flagvalue;
    countryName;
    defaultFlag: string;
    langAll: any;
    getType: any = {};

    timedOut = false;
    lastPing?: Date = null;
    checkLocalTeacher: any;
    constructor(
        private router: Router,
        private sharedDataService: SharedDataService,
        private cartService: CartService,
        public dialog: MatDialog,
        public languageService: LanguageService,
        public translate: TranslateService,
        private idle: Idle,
        private keepalive: Keepalive,
        private authServiceService: AuthServiceService,
    ) {
        this.idleLoad();
        this.translate.use(localStorage.getItem("lang"));
        this.checkLocalTeacher = JSON.parse(localStorage.getItem('currentUserLogin'));
    }

    listLang = [
        { text: "English", flag: "assets/img/us-flag.jpg", lang: "en" },
        { text: "Thai", flag: "assets/img/thai-flag.jpg", lang: "th" },
    ];

    ngOnInit(): void {
        var countdownLocal = localStorage.getItem("countdown-level-test");
        var timeCountLocal = JSON.parse(countdownLocal);
        if (localStorage.getItem("countdown-level-test")) {
            this.router.navigate([`/profile/level-exam-all/level-test/${timeCountLocal.mainId}`]);
        }

        this.langAll = LanguageAll[0];
        this.sharedDataService.languageValue.subscribe((message) => {
            if (message != "")
                this.translate.use(message);
        });
        this.getDataLoginLocal();
        this.loadContactData();
        this.loadCartList();
        this.sharedDataService.getDataUserLogin.subscribe(
            (message) => {
                if (message != "") {
                    const jsonValue = JSON.stringify(message);
                    const valueFromJson = JSON.parse(jsonValue)
                    this.getType = message;
                    if (message.result) {

                        this.studentName = "" + ((valueFromJson || {}).result || {}).fullNameTh;
                        this.profileImage = "" + Constants.REST_API_MAIN + (((valueFromJson || {}).result || {}).image ?? "");


                        if (message.result != undefined) {
                            this.loading = false;
                        }

                    } else {
                        this.studentName = "" + ((valueFromJson || {}) || {}).fullNameTh;
                        this.profileImage = "" + Constants.REST_API_MAIN + ((valueFromJson ?? {}).image ?? "");
                    }
                    if (message == "") {
                        this.loading = true;
                    }
                    if (message == "please-sign-in" && message != "") {
                        this.loading = false;
                        this.showMenuSignIn = true;
                    }
                }
                // else {
                //     this.loading = false;
                //     this.showMenuSignIn = true;
                //     console.log("getType",this.getType);

                // }
            }
        );

        this.langStoreValue = localStorage.getItem("lang");
        const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
        this.countryName = val.map((element) => element.text);
        if (val.length === 0) {
            if (this.flagvalue === undefined) {
                this.defaultFlag = "assets/images/flags/us.jpg";
            }
        } else {
            this.flagvalue = val.map((element) => element.flag);
        }
    }

    setLanguage(text: string, lang: string, flag: string) {
        this.classApplied2 = false;
        this.countryName = text;
        this.flagvalue = flag;
        this.langStoreValue = lang;
        this.languageService.setLanguage(lang);
    }

    getDataLoginLocal() {
        var local = localStorage.getItem("currentUserLogin");

        if (local == null) {
            // this.router.navigate([`/main`], { replaceUrl: true });
            this.checkLogin = false;
        } else {
            this.getCountCart();
        }
    }

    getCountCart() {
        this.sharedDataService.getCountCart.subscribe((message) => {
            // if ( message != undefined ) {
            if (Number(message) > 0) {
                this.cartList = true;
            } else {
                this.cartList = false;
            }

            this.countCartList = message;

        })
    }

    loadCartList() {
        this.cartService.GetCartList().then((res) => {
            if (res.status) {
                this.countCartList = res.data.length;
                this.sharedDataService.changeCountCart(this.countCartList);
                this.cartList = true;
            } else {
                this.cartList = false;
            }
        }, (error) => {
            console.log("error login : ", error);
        });
    }

    loadContactData() {
        var temp: any = [];
        var temp2: any = [];
        this.sharedDataService.getDataContact.subscribe((message) => {
            if (message != undefined && message != "" && message != null && message.status != false) {
                this.contactData = message.data;
                for (let index = 0; index < this.contactData.length; index++) {
                    if (this.contactData[index].contactStatus == true) {

                        if (this.contactData[index].phoneNumber != '') {
                            temp.push(this.contactData[index].phoneNumber);
                        } else if (this.contactData[index].email != '') {
                            temp2.push(this.contactData[index].email);
                        }
                    }
                }
                this.telData = temp;
                this.emailData = temp2;

            }
        })
    }

    somethingChanged() {
        if (this.search != "") {
            this.sharedDataService.changeSearchCount(this.search);
            this.router.navigate([`/course`]);
            this.toggleClass3()
            this.search = ""
        }
    }
    navigateToExternal() {
        const localLogin = JSON.parse(localStorage.getItem('currentUserLogin'));
        const token = localLogin.token; // or fetch from a cookie
        if (token) {
            const backOfficeUrl = `https://lms-mk-bof.achatsocial.com/auth/signin?token=${token}`;
            // const backOfficeUrl = `http://localhost:4200/auth/signin?token=${token}`;
            window.open(backOfficeUrl, '_blank');
        } else {
            alert('Please log in again.');
        }
    }
    onClicklogOut() {
        if (localStorage.getItem("countdown-level-test")) {
            Swal.fire({
                icon: "warning",
                title: "กรุณาทำข้อสอบวัดระดับให้เสร็จ!",
                html: `<p style='display: inline-table;'>หากอยู่ระหว่างทำข้อสอบวัดระดับ จะไม่สามารถออกจากระบบได้!</p>`,
                confirmButtonColor: 'var(--defaultBtnColor)',
                confirmButtonText: "ตกลง"
            });
        } else {
            localStorage.removeItem("currentUserLogin");
            localStorage.removeItem("showbanner");
            this.router.navigate([`/main`], { replaceUrl: true }).then(() => { window.location.reload(); });
        }
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }
    classAppliedNoti = false;
    Noti() {
        this.classAppliedNoti = !this.classAppliedNoti;
    }

    idleLoad() {
        var sum = 3600 * 3;
        this.idle.setIdle(sum);
        this.idle.setTimeout(3);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idle.onIdleEnd.subscribe(() => {
            this.resetIdle();
        });

        this.idle.onTimeout.subscribe(() => {
            this.timedOut = true;
            this.onClicklogOut();
        });

        this.keepalive.interval(15);
        this.keepalive.onPing.subscribe(() => (this.lastPing = new Date()));
        this.resetIdle();
    }

    resetIdle() {
        this.idle.watch();
        this.timedOut = false;
    }

    timerCountDown: any;
    outTime: string = "";
    currentTime: any;
}